@import ../environment

$gap: 15px
$light-gray: #e5e5e5

.course
  height: 85vh
  overflow: hidden
  margin-left: -14px

  +media-breakpoint-up(md)
    display: grid
    grid-template-columns: 0.7fr 1.6fr 0.8fr
    grid-template-rows: 1fr
    gap: 0px 0px
    grid-template-areas: "project-components course-unit-preview courses"

.course--project-components
  grid-area: project-components
  padding-right: $gap
  overflow-y: auto
  max-height: 80vh
  +beautiful_scrollbar


.course--course-unit-preview
  grid-area: course-unit-preview
  padding: $gap
  overflow-y: auto
  +beautiful_scrollbar


.course--courses
  grid-area: courses
  padding: $gap
  display: flex
  justify-content: center
