@import ../environment

.context-menu
  box-shadow: 0px 17px 132px rgba(46, 46, 46, 0.14), 0px 7.10219px 55.1464px rgba(46, 46, 46, 0.10064), 0px 3.79717px 29.4839px rgba(46, 46, 46, 0.083455), 0px 2.12866px 16.5284px rgba(46, 46, 46, 0.07), 0px 1.13052px 8.77813px rgba(46, 46, 46, 0.056545), 0px 0.470434px 3.65278px rgba(46, 46, 46, 0.0393604)

.context-menu--link
  --bs-link-color: #{$jet}
  --bs-link-hover-color: #{$jet}
  display: block
  padding: 1rem
  &:not(:last-child)
    border-bottom: 1px solid $jet-10


  &.-green
    --bs-link-color: #{$primary}
    --bs-link-hover-color: #{$primary}
