@import ../environment

.filter-content-types
  min-width: fit-content
  --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/content_types.svg)

  .item:before
    content: ''
    width: 1.5rem
    height: 1.5rem
    -webkit-mask-image: var(--item-icon-url)
    mask-image: var(--item-icon-url)
    -webkit-mask-size: cover
    mask-size: cover
    background-color: currentColor
    margin-right: 0.5rem

  input[type=checkbox]
    transform: scale(1.85)
    margin-right: 1rem

  .select
    margin-bottom: 0
