@import ../environment

.search-drawer
  position: absolute
  top: var(--header-height)
  up-drawer-box
    width: 100vw !important
    min-height: unset
    background-color: $primary
  +media-breakpoint-up($mobile-until)
    display: none
