@import ../environment

.round-action-link
  --bs-btn-disabled-color: var(--bs-white)
  --bs-btn-disabled-bg: var(--bs-gray-400)
  --bs-btn-disabled-border-color: var(--bs-gray-400)

  height: 44px
  width: 44px
  border-radius: 50%
  display: inline-flex
  align-items: center
  justify-content: center
  font-size: 1.5rem
  background-color: $primary
  color: $white
  padding: 10px

  &.-secondary
    background-color: $primary-10
    color: $primary

  &.-white
    background-color: $white
    color: $primary

  &.-small-font
    font-size: 1.25rem
