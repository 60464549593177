@import ../environment

$container-margin: calc(0.5 * (100vw - 100%))

.project-components-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 2.5rem
  +media-breakpoint-down($mobile-until)
    --tile-width: 15.75rem
    margin-inline: calc(-1 * $container-margin)
    padding-inline: $container-margin
    grid-auto-flow: column
    gap: 1.25rem
    overflow: auto
    +beautiful_scrollbar
    @media (orientation: landscape)
      --tile-width: 17rem
      gap: 1.5rem
