@import ../environment

.static-page
  position: relative
  margin: 1rem 0
  margin-bottom: 1rem * 2

  +media-breakpoint-up(md)
    font-size: 1.2rem
    margin: 4rem 0
    margin-bottom: 4rem * 2

  &.-narrower
    +media-breakpoint-up(xl)
      max-width: 1000px

.static-page--section
  &:not(:last-of-type)
    margin-bottom: 4rem

.static-page--add-on
  position: absolute
  right: 0
