@import ../environment

.player
  display: grid
  column-gap: 140px
  row-gap: 40px
  grid-template-columns: auto 300px
  grid-template-rows: auto 0.1fr min-content
  grid-template-areas: "preview course-units" ". course-units" "project-components project-components"

  +media-breakpoint-down(xxl)
    column-gap: 100px

  +media-breakpoint-down(xl)
    column-gap: 70px
    row-gap: 20px

  +media-breakpoint-down($mobile-until)
    display: flex
    gap: 4rem
    flex-direction: column

.player--preview
  grid-area: preview
  font-size: 14px

  &.-fullscreen
    display: flex
    justify-content: center
    overflow: auto


.player--course-units
  grid-area: course-units
  position: relative
  display: flex
  flex-direction: column

  .btn
    margin-top: 1.5rem

  +media-breakpoint-up($mobile-until)
    &::after
      content: ""
      position: absolute
      z-index: 1
      bottom: 0
      left: 0
      pointer-events: none
      width: 100%
      height: 1.5rem
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)


.player--project-components
  position: relative
  grid-area: project-components
  margin-top: 4rem
  margin-bottom: 6.125rem
  --carousel-buttons-gap: 5%

  +media-breakpoint-down($mobile-until)
    margin-top: 0
    margin-bottom: 4rem
