@import ../environment

.filter-project-components
  .btn-group
    display: flex
    gap: 0.5rem
    +media-breakpoint-down($mobile-until)
      flex-direction: column

    legend
      display: inline-block
      float: left
      width: fit-content
      padding-bottom: 0
      padding-top: 0.375rem !important
      margin-right: 0.75rem
      flex-shrink: 0

    .fieldset-buttons
      flex-wrap: wrap
      gap: 0.5rem

      label
        flex-grow: 0
        background-color: $white
        border-color: $sonic-silver-opaque
        border-radius: 100px !important
        padding: 0.375rem 0.75rem
        width: fit-content
        font-weight: 400
        line-height: 1.375rem
        color: $jet
        min-height: 38px

        &:hover
          border-color: unset

        input
          display: none

        &:first-of-type
          border-top-left-radius: inherit
          border-bottom-left-radius: inherit

        &.selected
          background-color: $sonic-silver-opaque
          color: $black

          &:after
            content: ''
            width: 1.5rem
            height: 1.5rem
            -webkit-mask-image: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/deselect_project_components.svg)
            mask-image: url(../../images/icons/deselect_project_components.svg)
            -webkit-mask-size: cover
            mask-size: cover
            background-color: currentColor
            margin-left: 0.5rem
