@import ~bootstrap/scss/mixins
@import ../../shared/config/mixins

=beautiful_scrollbar
  &::-webkit-scrollbar
    width: 8px
    height: 8px
  &::-webkit-scrollbar-track
    background-color: $white
    border-radius: 100px
  &::-webkit-scrollbar-thumb
    background-color: $gray
    border-radius: 100px

=scrollbar-area($scrollbar-area: var(--container-padding), $horizontal: false)
  // $scrollbar-area should ideally correspond to the padding of the scrollable container
  // this mixin will reserve enough space at the edge of the container to place the
  // scrollbar centered and well visible
  --spacing: calc(#{$scrollbar-area} / 2)

  @if $horizontal
    padding-bottom: var(--spacing)
    margin-bottom: var(--spacing)
  @else
    padding-right: var(--spacing)
    margin-right: var(--spacing)

=dragged-nugget-styles
  &.sortable-chosen
    // Element that was picked up. Also receives .sortable-drag in some cases, but not always (e.g. when swapping)
    --nugget-border-color: #{$primary}

  &.sortable-drag
    // Element being dragged around; we can't change opacity or use box-shadow, because of [draggable]
    --nugget-border-color: #{$primary}

  &.sortable-ghost
    // Placeholder at the drop location
    --nugget-border-color: transparent
    opacity: 0.5

  &.sortable-swap-highlight
    // Other element that will be swapped out by the dragged element; only relevant when using `swap: true`.
    --nugget-border-color: #{$primary}

  &.sortable-dropped
    animation: nugget-placed .3s $brand-transition-timing-function

=mobile-course-ui-asset-height
  min-height: 60px // this is only to hint to users on devices with very little height (e.g. horizontal phone) that there is content they could see if they rotate their device. horizontal phone can't be supported with the current designs.
  max-height: calc(100vh - 260px) // 260px = roughly the size of UI components around the slide
