@import ../environment

.course-units-grid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-auto-rows: 1fr
  column-gap: 2.5rem
  row-gap: 1.75rem
  margin-top: 0.75rem
  width: 100%
  +media-breakpoint-down(xxl)
    grid-template-columns: repeat(3, 1fr)
  +media-breakpoint-down($mobile-until)
    grid-template-columns: repeat(2, 1fr)
    gap: 1.5rem
  +media-breakpoint-down(md)
    grid-template-columns: repeat(1, 1fr)
