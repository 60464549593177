@import ../environment

$draggable-sources-from: lg
$nugget-size: 60px

%sources
  display: flex
  align-content: start
  gap: var(--spacer-2)
  flex-wrap: wrap
  overflow-y: auto

.image-assignment
  display: grid
  grid-template-areas: "text text" "sources base-image" "controls base-image" "explanation explanation"
  grid-template-columns: col-width(4) 1fr
  grid-template-rows: auto 1fr auto auto
  gap: 0 $grid-gutter-width

  height: 100%

  +media-breakpoint-down($draggable-sources-from)
    grid-template-columns: 1fr
    grid-template-areas: "text" "base-image" "controls" "explanation"
    gap: var(--spacer-3)

    overflow: auto
    +scrollbar-area(var(--padding))
    +beautiful_scrollbar

  &.-only-solution
    grid-template-areas: "text" "base-image" "explanation"
    grid-template-columns: minmax(0, 56rem)
    grid-template-rows: auto auto
    justify-content: center

.image-assignment--sources
  grid-area: sources
  @extend %sources

  +media-breakpoint-down($draggable-sources-from)
    display: none

  .image-assignment.-only-solution &
    display: none

.image-assignment--sources-modal
  @extend %sources
  --nugget-cursor: pointer

.image-assignment--text
  grid-area: text
  margin-bottom: var(--spacer-3)

.image-assignment--explanation
  grid-area: explanation
  margin-top: var(--spacer-3)

.image-assignment--controls
  grid-area: controls
  align-self: end

  .image-assignment.-only-solution &
    display: none

.image-assignment--base-image
  grid-area: base-image
  position: relative
  margin-inline: auto
  border: 1px solid $gray-400
  max-width: 100%
  height: max-content
  border-radius: $border-radius-sm
  box-shadow: $shadow-sm
  overflow: hidden

  > img
    width: 100%
    pointer-events: none
    user-select: none

  &:empty
    display: none

.image-assignment--drop-target
  --size: 8%

  width: var(--size) // 8% of image width; height set to same value via ::before
  position: absolute
  top: var(--y)
  left: var(--x)
  margin-top: calc(var(--size) * (-0.5))
  margin-left: calc(var(--size) * (-0.5))
  background-color: white
  border-radius: 100%
  box-shadow: inset 0 0 0 2px $primary

  &::before
    content: ''
    display: block
    padding-top: 100% // relative to container *width* => 100% = square

  > .image-assignment--nugget:not(.draggable-mirror)
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%

  &.-incorrect
    box-shadow: 0 0 0 6px $danger
    --nugget-cursor: #{''}
    --nugget-border-color: $white

  &.-correct
    box-shadow: 0 0 0 6px $primary
    --nugget-cursor: #{''}
    --nugget-border-color: $white

.image-assignment--nugget
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden

  background-color: white
  border: 2px solid var(--nugget-border-color, $dark-primary)
  border-radius: 100vmax
  cursor: var(--nugget-cursor, move)
  user-select: none
  height: $nugget-size
  width: $nugget-size

  .image-assignment.-only-solution &
    cursor: default

  > img
    width: 100%
    height: 100%
    object-fit: contain

  +dragged-nugget-styles


@keyframes nugget-placed
  0%
    opacity: 0.5
    scale: 1.05
    border-color: $primary
    box-shadow: $shadow-dragging

  100%
    scale: 1
    opacity: 1
    border-color: $primary-25
    box-shadow: 0 0 0 transparent
