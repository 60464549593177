@import ../environment

.slide
  font-size: 1rem
  background-color: $gray
  padding: 2rem
  border-radius: var(--slide-border-radius, 1.5rem)
  display: flex
  flex-direction: column
  height: 65vh
  +media-breakpoint-down(xl)
    max-height: 100vh
    .btn
      padding: 10px

.slide--content
  flex-grow: 1
  max-height: 100%
  overflow-y: auto
  width: 100%
  display: flex
  justify-content: center

.slide--type-icon
  color: $primary
  display: inline-block
  margin-right: .5rem
  font-size: 1rem

.slide--content-type
  color: $sonic-silver
  font-family: Montserrat, normal
  font-weight: 800
  font-size: 1rem
  display: inline-block

.slide--title
  margin: 0.5rem 0

  .-fullscreen &
    font-size: 2.5vmax
