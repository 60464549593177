@import ../environment

$horizontal-from: $horizontal-question-layout-from

.multiple-choice
  display: flex
  flex-direction: column

  +media-breakpoint-up($horizontal-from)
    align-items: center

  +media-breakpoint-up($horizontal-from)
    display: grid
    gap: 0 var(--spacer-5)

    max-width: map-get($grid-breakpoints, sm)
    margin-inline: auto
    grid-template-columns: 1fr
    grid-template-rows: repeat(3, auto)
    grid-template-areas: "text" "start-button" "choices" "submit-button"

.multiple-choice--text
  grid-area: text

  // Question texts should use a large font, but not be too large on medium-sized viewports.
  // This uses a flexible width of 1.8vw, but contains it within h2 and h3 font size.
  // Note that this scales more than our regular RFS headings.
  font-size: question-font-size()
  font-weight: $font-weight-bold
  font-family: $headings-font-family
  line-height: $headings-line-height
  color: $headings-color

  margin-bottom: var(--vertical-spacer, var(--spacer-3))


.multiple-choice--choices
  grid-area: choices
  // note content may be hidden, but not the element itself


.multiple-choice--start-button
  grid-area: start-button


.multiple-choice--submit-button
  grid-area: submit-button

  > *
    margin-top: var(--vertical-spacer)
    margin-bottom: var(--vertical-spacer)
