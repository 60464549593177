@import ../environment

.courses
  width: 90%
.courses--header
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
.courses--title
  margin-bottom: 0

