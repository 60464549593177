@use "sass:math"
@import ./functions

$enable-shadows: false
$enable-gradients: false

$brand-transition-timing-function: cubic-bezier(0.61, 1, 0.88, 1)

// Layout
$mobile-until: lg
$padding-desktop-top-bottom: 5rem
$padding-mobile-top-bottom: 4rem

$fullscreen-course-ui-until: md
$horizontal-question-layout-from: lg

// Bootstrap variables we need to define container widths
$grid-gutter-width: 1.5rem
$container-padding-x: $grid-gutter-width

$container-content-max-width: 1rem * math.div(1296, 16)
$container-max-width-incl-padding: $container-content-max-width + $container-padding-x * 2
$container-max-widths: (xs: 100%, sm: 100%, md: 100%, lg: 100%, xl: 100%, xxl: $container-content-max-width)

/// Bootstrap configuration

$primary: #159A34
$dark-primary: #11802B
$primary-25: transparentize($primary, 0.75)
$primary-10: #E8F5EB

$secondary: #E8F5EB
$dark-secondary: #E1EDE3

$success: $primary
$danger: #D80000
$warning: #ffaa00
$info: #25A0C6

$white:    #fff
$black:    #000

// colors from design
$jet: #2E2E2E
$jet-80: transparentize($jet, 0.20)
$jet-25: transparentize($jet, 0.75)
$jet-10: transparentize($jet, 0.90)
$jet-opaque: #585858
$sonic-silver: #787878
$sonic-silver-opaque: #DDDDDD
$gray: #F5F5F5
$group-children: #EC3434
$group-youth: $info
$group-cyclists: #49A645
$group-seniors: #195AA6

$light: $gray
$light-gray: #FAFAFA

$font-family-base: Lato, normal
$headings-font-family: Montserrat, normal
$headings-font-weight: 800

$btn-font-family: Lato, normal
$btn-font-weight: 600
$btn-line-height: 1rem

$link-font-weight: 600

$h1-font-size: 2.25rem
$h2-font-size: 1.375rem
$h3-font-size: 1.15rem
$h4-font-size: 1rem

$smaller-font-size: 12px

$display-font-weight: 800
$display-line-height: 1.25

$box-shadow: 0px -2px 50px rgba(21, 154, 52, 0.1), 0px -1.10912px 27.9023px rgba(21, 154, 52, 0.0759687), 0px -0.431745px 14.0168px rgba(21, 154, 52, 0.054165), 0px -0.176703px 6.72178px rgba(21, 154, 52, 0.0342921), 0px -0.0670403px 2.57452px rgba(21, 154, 52, 0.0162213)
$shadow-sm: 0px -2px 55px rgba(192, 192, 192, 0.18), 0px -0.446726px 12.285px rgba(192, 192, 192, 0.1194), 0px -0.133002px 3.65756px rgba(192, 192, 192, 0.0906)
$shadow-dragging: 0px 0px 15px rgba(101, 193, 69, 0.5)

// Buttons & Form controls
$border-radius: 10px
$btn-transition: none

$input-btn-padding-y: 14px
$input-btn-padding-x: 24px

$badge-font-size:                   1rem
$badge-font-weight:                 normal
$badge-color:                       $primary
$badge-padding-y:                   14px
$badge-padding-x:                   14px
$badge-border-radius:               5px



@import ../../shared/config/bootstrap_variables_loader

////////////////////////////////////////////////////////////////////////////////
// Custom variable declarations after Bootstrap variables have been initialized.
// Note that none of these overrides a Bootstrap variable.
////////////////////////////////////////////////////////////////////////////////
