@import ../environment

.project-component
  &.-active
    border-color: $primary
    color: $primary

.project-component--header
  border: 1px solid transparent
  border-bottom-color: $gray
  padding: 14px
  background-color: $white
  border-radius: 10px
  margin: 8px 0
  width: 100%

  &:hover
   border: 1px solid $sonic-silver-opaque

.project-component--title
  font-weight: 700

.project-component--details
  display: flex
  flex-direction: row
  color: $sonic-silver
  gap: 10px
  font-size: 14px

