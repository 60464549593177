@import ../environment

.animated-filters
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%

  +media-breakpoint-down($mobile-until)
    flex-direction: column
    visibility: hidden
    max-height: 0

    &.slide-down
      margin-bottom: 1.5rem
      animation: slide-down 0.4s linear both

    &.slide-up
      animation: slide-up 0.3s linear both

    @keyframes slide-down
      from
        visibility: hidden
        max-height: 0

      to
        visibility: visible
        max-height: 500px

    @keyframes slide-up
      from
        visibility: visible
        max-height: var(--height)

      to
        visibility: hidden
        max-height: 0
