@import ../environment

// Name chosen to align with Bootstrap nav styles
.nav-footer
  display: flex
  flex-direction: row
  line-height: 22px
  justify-content: space-between
  align-items: center
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    justify-content: center
    align-content: center
    @media (orientation: landscape)
      flex-wrap: nowrap

  .navbar-text
    padding: 0
    text-align: left
    +media-breakpoint-down($mobile-until)
      width: 100%
      text-align: center
      @media (orientation: landscape)
        text-align: left

  .nav-link
    padding: 0
    text-align: center

.nav-footer--heading
  color: $primary
  font-size: $h4-font-size
  font-family: $headings-font-family
  margin-bottom: 1.75rem

.nav-footer--links
  display: flex
  flex-direction: row
  gap: 2.5rem
  margin-bottom: 0.625rem
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    gap: 0.625rem
    margin-bottom: 0.875rem

.nav-footer--copyright
  color: $sonic-silver
  +media-breakpoint-down($mobile-until)
    margin-bottom: 2.25rem

.nav-footer--logos
  display: flex
  gap: 1.25rem
  justify-content: center
