@import ../../environment

.container,
.container-fluid
  .page--content > &
    padding-left: 0
    padding-right: 0

  &.container-max-sm
    max-width: map-get($container-max-widths, md)
  &.container-max-md
    max-width: map-get($container-max-widths, lg)
  &.container-max-lg
    max-width: map-get($container-max-widths, xl)
  &.container-max-xl
    max-width: map-get($container-max-widths, xxl)
