@import custom_bootstrap_mixins

=only-in-tests
  body[data-environment='test'] &
    @content

=not-in-tests
  body:not([data-environment='test']) &
    @content

=dimensions($width, $height)
  width: $width
  +height($height)

=height($height)
  height: $height
  line-height: floor($height / 2) * 2 // Don't use odd line-height values. Browser randomly place content at the given value, or the preceding even number, or the following even number.

=margin-y($margin)
  margin-top: $margin
  margin-bottom: $margin

=margin-x($margin)
  margin-left: $margin
  margin-right: $margin

=padding-x($amount)
  padding-left: $amount
  padding-right: $amount

=padding-y($amount)
  padding-top: $amount
  padding-bottom: $amount

=blurring-background($color, $opacity: 0.8, $blur: .5rem)
  // Semi-transparent background which blurs the underlying layers.
  // When blurring is unsupported (e.g. Firefox), we increase opacity for readability.
  background: rgba($color, $opacity * 1.2)
  @supports (backdrop-filter: blur(0))
    background: rgba($color, $opacity)
    backdrop-filter: grayscale(30%) blur($blur)
