@import ../environment

$toggle-right: $card-spacer-x
$collapsed-toggle-top: $card-spacer-y
$expanded-toggle-top: $card-spacer-y * 0.8

.collapsible--toggle
  position: relative
  padding-right: 3rem
  cursor: pointer

  &:before
    position: absolute
    top: $expanded-toggle-top
    right: $toggle-right
    text-decoration: none !important
    content: ''
    display: inline-block
    border-right: 2px solid $sonic-silver
    border-bottom: 2px solid $sonic-silver
    width: 10px
    height: 10px
    transform: rotate(225deg)

    .collapsible[data-collapsed] &
      top: $collapsed-toggle-top
      content: ''
      display: inline-block
      border-right: 2px solid $sonic-silver
      border-bottom: 2px solid $sonic-silver
      width: 10px
      height: 10px
      transform: rotate(45deg)

.collapsible--body

  .collapsible[data-collapsed] &
    display: none

.collapsible--placeholder
  display: none

  .collapsible[data-collapsed] &
    display: block

