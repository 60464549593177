@import ../environment

.video-slide
  display: flex
  align-items: center
  justify-content: center
  width: 85%

  main.container.-fullscreen &
    width: 100%
    background-color: black
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0

