@import ../environment

.footer-logo
  +media-breakpoint-down(lg)
    height: 7.375rem
    &.-square
      width: 7.375rem

  img
    width: 100%
    +media-breakpoint-up(lg)
      height: 100%
