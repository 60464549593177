@import ../environment

.linked-hint
  --bs-link-color: #{$sonic-silver}
  --bs-link-hover-color: #{$jet-25}

  --bs-link-decoration: #{underline dotted}
  --bs-link-hover-decoration: #{underline dotted}
  font-style: italic
  color: $sonic-silver
  line-height: 1.25rem
  text-underline-offset: 2px
