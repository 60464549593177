@import ../environment

.interactive-slide
  flex-grow: 1
  width: 100%

  .standalone-preview &
    iframe
      height: 45vh
      +media-breakpoint-down($mobile-until)
        width: 70vw
        height: 60vh

  .container.-fullscreen &
    iframe
      height: 70vh
