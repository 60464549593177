// Bootstrap's .toast did not fit our case well enough, so we re-implemented something similar to .toast
// This component is shared across environments, so we did not specify shadows or rounded corners. Please apply utility classes like `.shadow` or `.rounded` as needed.

@import ../config/mixins

$line-height: var(--bs-body-line-height)
$font-size: var(--bs-body-font-size)

.flash-message
  pointer-events: auto
  position: relative

  display: grid
  grid-template-columns: min-content auto min-content min-content
  grid-template-rows: auto
  grid-template-areas: "icon text close"
  gap: .5rem
  align-items: start

  font-size: $font-size
  line-height: $line-height

  +blurring-background(var(--bs-white-rgb), 0.8)

  border: 1px solid rgba(var(--bs-black-rgb), 0.1)
  overflow: hidden

  width: 25rem
  max-width: 100%
  padding: .75rem

  --flash-color: var(--bs-body-color)
  &.-success
    --flash-color: var(--bs-success)
  &.-info
    --flash-color: var(--bs-info)
  &.-warning
    --flash-color: var(--bs-warning)
  &.-danger
    --flash-color: var(--bs-danger)

.flash-message--icon
  grid-area: icon
  color: var(--flash-color)
  font-size: calc(#{$line-height} * 1rem)
  line-height: $font-size
  --icon-line-height: #{$font-size}

.flash-message--text
  grid-area: text
  color: var(--flash-color)
  overflow: hidden
  overflow-wrap: break-word

.flash-message--close
  grid-area: close
  margin: -.25rem
  padding: .25rem
  &:not(:hover)
    color: var(--bs-gray)

.flash-message--timer-indicator
  position: absolute
  top: 0
  left: 0
  height: .25rem
  width: 100% // updated from flash_message.js
  background-color: var(--flash-color)
