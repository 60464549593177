@import ../environment

.multiple-choice-choices
  display: flex
  flex-direction: column
  justify-content: center
  gap: var(--spacer-2)

  +media-breakpoint-up(md)
    justify-content: center
