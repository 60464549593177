@import ../environment

.video-section
  background-color: $light-gray
  padding-top: $padding-mobile-top-bottom
  padding-bottom: $padding-mobile-top-bottom
  text-align: center
  +media-breakpoint-up($mobile-until)
    padding-top: $padding-desktop-top-bottom
    padding-bottom: $padding-desktop-top-bottom

.video-section--text
  .-green
    color: $primary
  .display-text
    +media-breakpoint-up(lg)
      padding-left: 10%
      padding-right: 10%
    +media-breakpoint-up(xl)
      padding-left: 20%
      padding-right: 20%

.video-section--video
  video
    border-radius: 20px
    width: 100%
    +media-breakpoint-up($mobile-until)
      width: 50%
