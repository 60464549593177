@import ../environment

.quiz-slide
  margin: 2rem 0
  width: 100%
  +media-breakpoint-down($mobile-until)
    margin: 0

.quiz-slide--solution
   margin: 1rem 0

.quiz-slide--question
  +media-breakpoint-down($mobile-until)
    font-size: 1rem

.quiz-slide--answers
   margin: 2rem 0
   +media-breakpoint-down($mobile-until)
     margin: 0
