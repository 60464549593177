@import ../environment

.course-unit
  --bs-link-color: #{$jet}
  --bs-link-hover-color: #{$jet}
  display: grid
  position: relative
  grid-template-columns: 0.5fr 1.5fr
  grid-template-rows: 1fr 1fr
  gap: 0px 8px
  grid-template-areas: "course-unit--icon course-unit--title" "course-unit--icon course-unit--details"
  width: 100%
  height: 60px
  border-radius: $border-radius
  padding: 8px
  box-sizing: border-box
  border: 1px solid transparent
  margin: 4px 0

  &:hover
    border-color: $sonic-silver-opaque

  &.-active
      --bs-link-color: #{$primary}
      --bs-link-hover-color: #{$primary}
      border-color: $primary-25
      color: $primary

.course-unit--icon
  grid-area: course-unit--icon
  color: $sonic-silver-opaque
  background-color: $gray
  display: flex
  align-items: center
  justify-content: center
  padding: 10px
  border-radius: $border-radius
  height: 42px
  width: 44px

.course-unit--title
  grid-area: course-unit--title
  font-size: 14px

.course-unit--details
  grid-area: course-unit--details
  font-size: 12px
  display: flex
  flex-direction: row
  gap: 0.5rem
  color: $sonic-silver


.course-unit--remove-button
  position: absolute
  z-index: 999
  top: 0
  right: 5px
  font-size: 1rem
  display: none

  .course.-updatable .courses .course-unit:hover &
    display: inline
