@import ../environment

.teacher-section
  display: flex
  gap: 2rem
  padding-top: $padding-desktop-top-bottom
  +media-breakpoint-up(xxl)
    margin-bottom: 0
  +media-breakpoint-down(xxl)
    padding-bottom: $padding-desktop-top-bottom
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    padding-top: $padding-mobile-top-bottom
    padding-bottom: $padding-mobile-top-bottom

.teacher-section--text
  margin-top: spacer(5)
  max-width: 35rem

  .-green
    color: $primary

  a
    font-size: $font-size-base

  .display-text
    margin-top: 2.375rem
    margin-bottom: 2.375rem
    +media-breakpoint-down($mobile-until)
      margin-top: 1.5rem

  +media-breakpoint-down($mobile-until)
    max-width: 100%
    margin-top: 0

.teacher-section--image
  max-width: 50%
  +media-breakpoint-down($mobile-until)
    max-width: 100%
  img
    width: 90%
    +media-breakpoint-down($mobile-until)
      width: 100%

.teacher-section--buttons
  display: flex
  align-items: center
  gap: 2.5rem
  justify-content: center
  +media-breakpoint-up(sm)
    justify-content: flex-start

