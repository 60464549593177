.colorized-svg
  display: inline-block
  position: relative

  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

    background-color: currentColor

    -webkit-mask-position: center
    -webkit-mask-size: cover
    -webkit-mask-image: var(--url)

    mask-position: center
    mask-size: cover
    mask-image: var(--url)

.colorized-svg--original
  visibility: hidden
  height: 100%
  width: 100%

.colorized-svg--text
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  text-align: center
  line-height: 30px
  font-size: 10px
