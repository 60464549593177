@import ../environment

.course-units-section
  background-color: $light-gray
  padding-top: $padding-mobile-top-bottom
  padding-bottom: $padding-mobile-top-bottom
  +media-breakpoint-up($mobile-until)
    padding-top: $padding-desktop-top-bottom
    padding-bottom: $padding-desktop-top-bottom

.course-units-section--course-units
  display: flex
  flex-direction: column
  align-items: center

  +media-breakpoint-down($mobile-until)
    align-items: flex-start

  .next-page
    align-self: flex-start
    +media-breakpoint-down($mobile-until)
      width: 100%
    .btn
      margin-top: 1.75rem
      +media-breakpoint-down($mobile-until)
        margin-top: 1.5rem
        width: 100%

  .fieldset-buttons
    display: flex
