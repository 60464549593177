@import ../environment

.carousel
  position: relative


.carousel--previous-page, .carousel--next-page
  position: absolute
  top: 50%
  transform: translate(0, -50%)
  +media-breakpoint-down($mobile-until)
    display: none


.carousel--previous-page
  left: calc(-1 * var(--carousel-buttons-gap, 10%))


.carousel--next-page
  right: calc(-1 * var(--carousel-buttons-gap, 10%))
