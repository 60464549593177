\:root
  --icon-line-height: var(--bs-body-line-height)

.icon
  --size: var(--app-icon-size)
  --margin-left: var(--app-icon-margin-left, 0)
  --margin-right: var(--app-icon-margin-right, 0)

  display: inline-grid
  align-items: center
  gap: 0.35em // more than a space character
  vertical-align: top
  height: calc(1em * var(--icon-line-height))

  button &
    pointer-events: none

.icon--image
  grid-row: 1
  grid-column: 1

  fill: currentColor
  width: var(--size, 1em)
  height: var(--size, 1em)

  margin-left: var(--margin-left)
  margin-right: var(--margin-right)

.icon--text
  grid-row: 1
  grid-column: 2
