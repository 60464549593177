@import ../environment

.game-slide
  margin: 1rem 0
  width: 100%
  +media-breakpoint-down($mobile-until)
    margin: 0

.game-slide--notification
  --padding: var(--spacer-3)

  padding: var(--padding)
  margin-bottom: 1rem
  border-radius: 10px

  +media-breakpoint-down($fullscreen-course-ui-until)
    --padding: var(--spacer-2)

  --color: #{$white}
  --background: #{$info}

  --app-icon-size: 20px
  --app-icon-margin-right: var(--spacer-1)

  background: var(--background)
  color: var(--color)

  line-height: 1.25
  text-align: center
  font-weight: bold

  transition: brand-transition(color, background)

  +media-breakpoint-down($fullscreen-course-ui-until)
    padding: var(--spacer-2)

  &.-success
    --color: #{$white}
    --background: #{$success}

  &.-failure
    --color: #{$white}
    --background: #{$danger}

  &.-warning
    --color: #{$warning}

  &.-locked
    --color: #{$white}
    --background: #{$info}

.game-slide--body
  +media-breakpoint-down($mobile-until)
    font-size: 1rem
