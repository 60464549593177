@import ../environment

.question
  text-align: left
  +media-breakpoint-up(lg)
    padding-left: 9rem
    padding-right: 9rem
  +media-breakpoint-up(xxl)
    padding-left: 17.5rem
    padding-right: 17.5rem

.question--question-text
  font-weight: 700
  color: $primary
  &:hover
    cursor: pointer
  &:before
    top: 0.2rem !important

.question--answer-text
  margin-top: 0.5rem

.question--divider
  border-top: 1px solid $sonic-silver-opaque
  width: 100%
  margin: 1rem 0
