@import ../environment

.assessment-slide
  display: grid

  grid-template-areas: "heading" "buttons-or-text" "cta"
  place-content: center
  place-items: center
  gap: var(--spacer-4)

.assessment-slide--heading
  grid-area: heading
  margin: 0

.assessment-slide--buttons
  grid-area: buttons-or-text

  .btn
    --bs-btn-line-height: auto
    --app-icon-size: 2rem
    --icon-line-height: 2rem

  transition: opacity ease-out .5s
  .assessment-slide.-submitted &
    opacity: 0
    pointer-events: none

.assessment-slide--text
  grid-area: buttons-or-text

  transition: opacity ease-out .5s
  .assessment-slide:not(.-submitted) &
    opacity: 0
    pointer-events: none
