@import ../environment

.course-units-list
  display: flex
  flex-direction: column
  gap: 0.85rem
  padding-right: 1.5rem
  margin-top: 0.75rem
  overflow-y: scroll
  height: 75vh
  +beautiful_scrollbar
