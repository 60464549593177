@import ../environment

.standalone-preview
  margin-bottom: 1rem

.standalone-preview--info
  color: $sonic-silver
  +media-breakpoint-down($mobile-until)
    display: none

.standalone-preview--warning
  display: none
  margin: 2rem 0
  +media-breakpoint-down($mobile-until)
    @media (orientation: portrait)
      display: block

.standalone-preview--slide
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  gap: 2rem
  +media-breakpoint-down($mobile-until)
    gap: 1rem

.standalone-preview--current-slide
  position: relative
  width: 90%
  +media-breakpoint-down($mobile-until)
    width: 100%
    @media (orientation: landscape)
      transform: scale(0.9)

  .slide
    box-shadow: 0px -2px 50px rgba(21, 154, 52, 0.1), 0px -1.10912px 27.9023px rgba(21, 154, 52, 0.0759687), 0px -0.431745px 14.0168px rgba(21, 154, 52, 0.054165), 0px -0.176703px 6.72178px rgba(21, 154, 52, 0.0342921), 0px -0.0670403px 2.57452px rgba(21, 154, 52, 0.0162213)
    background-color: $primary-10
    font-size: 1.25rem
    height: 65vh
    overflow-y: auto
    +media-breakpoint-down($mobile-until)
      @media (orientation: landscape)
        height: 90vh
    +media-breakpoint-down($mobile-until)
      font-size: 1rem
      padding: 1rem



  main.container.-fullscreen &
    .slide
      height: 85vh
      border-radius: unset
      +media-breakpoint-down($mobile-until)
        @media (orientation: landscape)
          height: 100vh

.standalone-preview--next-slide, .standalone-preview--previous-slide
  width: 3rem
  +media-breakpoint-down($mobile-until)
    @media (orientation: portrait)
      display: none
