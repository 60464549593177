@import ../environment

.filter
  .item
    font-weight: 700
    min-width: 10rem

  legend, label
    font-weight: 700

  &.-with-background
    .form-select
      background-color: $gray !important
      border-color: transparent

  .ts-dropdown
    margin-top: 0.75rem !important
    box-shadow: 0 17px 132px rgb(46 46 46 / 14%), 0 7.10219px 55.1464px rgb(46 46 46 / 10%), 0 3.79717px 29.4839px rgb(46 46 46 / 8%), 0 2.12866px 16.5284px rgb(46 46 46 / 7%), 0 1.13052px 8.77813px rgb(46 46 46 / 6%), 0 0.470434px 3.65278px rgb(46 46 46 / 4%)

  .ts-dropdown-content
    max-height: unset
    +media-breakpoint-down($mobile-until)
      max-height: 300px

  .ts-wrapper.multi
    .ts-control
      cursor: pointer
      padding: 14px 42px 14px 14px
    .has-items
      .ts-control
        padding: 14px 24px
    .ts-dropdown
      width: inherit !important

  .option
    padding: 12px
    border-bottom: 1px solid $jet-10

    &:last-child
      border: none
