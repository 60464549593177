@import ../environment

.courses-section
  padding: $padding-mobile-top-bottom 0
  +media-breakpoint-up($mobile-until)
    padding: $padding-desktop-top-bottom 0

.courses-section--text
  max-width: 70%
  +media-breakpoint-down($mobile-until)
    max-width: 100%

.courses-section--courses
  display: flex
  flex-wrap: wrap
  gap: 2.5rem
  margin-top: spacer(5)
  +media-breakpoint-down($mobile-until)
    margin-bottom: 2rem
