@import environment
@import ../shared/root
@import ../fonts/lato
@import ../fonts/montserrat

body
  min-height: 100vh
  overflow-y: scroll
  color: $jet

h4
  font-weight: 700

a
  font-weight: $link-font-weight

*:focus
  outline: none

img
  // Ask Chrome to sharpen images that were scaled down.
  // Note that this is not the same as "crisp-edges" which is just nearest-neighbor scaling
  // and produces significantly worse results (e.g. for images containing circles).
  image-rendering: -webkit-optimize-contrast

  &[data-sizes='auto']
    // https://github.com/aFarkas/lazysizes#automatically-setting-the-sizes-attribute
    display: block
    width: 100%
