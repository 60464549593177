@import ../environment

$container-margin: calc(0.5 * (100vw - 100% - var(--scrollbar-width, 0px)))

.intro-section
  display: flex
  justify-content: space-between
  max-height: 33rem
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    text-align: center
    max-height: unset

.intro-section--text
  margin-top: spacer(5)
  max-width: 50%
  min-width: 50%

  .-green
    color: $primary

  a
    font-size: $font-size-base

  +media-breakpoint-down($mobile-until)
    max-width: 100%

.intro-section--image
  margin-right: calc(-1* $container-margin)
  +media-breakpoint-down($mobile-until)
    margin-left: calc(-1* $container-margin)

  img
    object-fit: cover
    object-position: left
    width: 100%
    height: 100%


.intro-section--buttons
  display: flex
  flex-direction: column
  align-items: center
  gap: 1rem
  justify-content: center
  +media-breakpoint-up(sm)
    flex-direction: row
  +media-breakpoint-up($mobile-until)
    gap: 1.5rem
    justify-content: flex-start
