@import ../environment

.header-navigation
  display: flex
  flex-direction: row
  align-items: center
  gap: 5rem
  width: 100%
  height: 100%
  +media-breakpoint-down(xl)
    gap: 4rem
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    gap: 1.75rem
    justify-content: center
    font-size: $h2-font-size
    line-height: 1.75rem
    .btn
      font-size: $h2-font-size
      line-height: 1.75rem

  .btn-link:focus
    box-shadow: none

.header-navigation--section
  display: flex
  flex-direction: row
  gap: 2.5rem
  align-items: center
  +media-breakpoint-down($mobile-until)
    width: 100%
    gap: 1.75rem
    flex-direction: column

  .btn-link:focus
    box-shadow: none

.header-navigation--divider
  border-top: 1px solid $white
  opacity: 0.3
  width: 100%

.header-navigation--button
  +media-breakpoint-down($mobile-until)
    width: 100%
    background-color: $white
    color: $primary

.header-navigation--search
  flex-grow: 1
