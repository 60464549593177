@import ../environment

.filter-target-groups
  --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/target_groups.svg)
  [data-value="children"], [for="target_group_children"]
    color: $group-children
    --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/kids.svg)

  [data-value="adolescents"], [for="target_group_adolescents"]
    color: $group-youth
    --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/adolescents.svg)

  [data-value="cyclists"], [for="target_group_cyclists"]
    color: $group-cyclists
    --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/cyclists.svg)

  [data-value="elderly"], [for="target_group_elderly"]
    color: $group-seniors
    --item-icon-url: url(/var/www/elearning_dvw_p/releases/20250213071253/app/assets/frontend/components/../../images/icons/elderly.svg)

  +media-breakpoint-down($mobile-until)
    width: 100%

  .item:before, .btn-group label:before
    content: ''
    width: 1.5rem
    height: 1.5rem
    -webkit-mask-image: var(--item-icon-url)
    mask-image: var(--item-icon-url)
    -webkit-mask-size: cover
    mask-size: cover
    background-color: currentColor
    margin-right: 0.5rem

  .btn-group
    max-width: 100%
    width: fit-content
    margin-bottom: 3rem

    .fieldset-buttons
      background-color: $white
      border: 1px solid $sonic-silver-opaque
      border-radius: $input-border-radius

      label
        padding: 0.875rem 1.75rem
        border-right: 3px solid transparent

        &:hover, &.selected
          border-width: 2px
          border-color: unset
          border-bottom-left-radius: inherit
          border-top-left-radius: inherit
          + label:not(:hover, .selected)
            border-color: transparent

        input
          display: none

        + label
          border-left: 1px solid $sonic-silver-opaque
          border-bottom-left-radius: 0
          border-top-left-radius: 0
