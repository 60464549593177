@import ../../environment

up-popup
  margin-top: 1rem
  padding: 0
  border-radius: 10px
  box-shadow: none

  // Fix for an Unpoly bug where it assigns `align="right"` to right-aligned popup containers.
  // Unfortunately, the "align" HTML attribute implies `text-align`, and we don't want that one to change.
  text-align: start
