@import ../environment

.footer
  --bs-link-color: #{$jet}
  --bs-link-hover-color: #{$sonic-silver}

  background-color: $light-gray
  padding: 1.75rem 11.5rem
  color: $sonic-silver-opaque

  +media-breakpoint-down(xl)
    padding: 1.75rem 4rem

  +media-breakpoint-down($mobile-until)
    padding: 3rem 1.5rem

  a
    font-weight: $font-weight-normal
