@import ../environment

.game-explanation
  --padding: var(--spacer-4)

  padding: var(--padding)
  background-color: $primary-25
  border-radius: 10px

  +media-breakpoint-down($fullscreen-course-ui-until)
    --padding: var(--spacer-2)

    overflow: auto
    +scrollbar-area(var(--padding))
    +beautiful_scrollbar

.game-explanation--title
  --app-icon-size: 20px

  font-weight: $font-weight-bold

.game-explanation--text
  margin-top: var(--spacer-2)
