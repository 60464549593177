@import ../environment

.filter-container
  display: flex
  flex-direction: column
  gap: 0.5rem

  +media-breakpoint-up(md)
    flex-direction: row
    gap: 1.5rem

  +media-breakpoint-up(lg)
    gap: 1rem
