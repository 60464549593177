@import ../environment

.course-panel-interaction
  display: flex
  align-items: center
  gap: 1rem

.course-panel-interaction--duration
  color: $sonic-silver
  font-size: $font-size-base
  flex-grow: 1
