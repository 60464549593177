@import ../environment

.search--input
  padding: 8px 12px

.search--button
  --bs-btn-color: #{$black}
  --bs-btn-hover-border-color: #{$jet-25}
  border: 1px solid $jet-25
  border-left: none
  padding: 14.5px

