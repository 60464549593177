@import ../environment

.project-components-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-auto-rows: 1fr
  gap: 2.5rem
  +media-breakpoint-down($mobile-until)
    grid-template-columns: repeat(2, 1fr)
    gap: 1.5rem
  +media-breakpoint-down(md)
    grid-template-columns: repeat(1, 1fr)
