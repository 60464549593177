@import ../environment

.header
  --bs-link-color: #{$jet}
  --bs-link-hover-color: #{$jet}
  display: flex
  flex-direction: row
  align-items: center
  height: 7.25rem
  line-height: 1rem
  +media-breakpoint-down($mobile-until)
    height: 4rem
    margin: 1rem 0

.header--logo
  margin-right: 2.5rem
  img
    height: 4.3rem
    +media-breakpoint-down($mobile-until)
      height: 3.5rem

.header--navigation
  flex-grow: 1
  --bs-navbar-toggler-font-size: 2rem

  .btn-link:focus
    box-shadow: none

  +media-breakpoint-up($mobile-until)
    a.up-current
      color: $primary
      &.btn
        color: $white

.header--toggler
  border: none
  color: $jet
  &:focus
    box-shadow: none
