@import ../environment

.preview-header
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: end
  margin: 1rem 0

  .container.-fullscreen &
    +media-breakpoint-down($mobile-until)
      @media (orientation: landscape)
        display: none

.preview-header--title
  display: flex
  flex-direction: column
  gap: 0.5rem
  +media-breakpoint-down($mobile-until)
    flex-direction: row
