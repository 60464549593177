@import ../environment

.faq-section
  background-color: $primary-10
  padding-top: $padding-mobile-top-bottom
  padding-bottom: $padding-mobile-top-bottom
  text-align: center
  +media-breakpoint-up($mobile-until)
    padding-top: $padding-desktop-top-bottom
    padding-bottom: $padding-desktop-top-bottom

.faq-section--questions
  display: flex
  flex-direction: column

.faq-section--pagination
  display: flex
  justify-content: center
  .page-link
    background-color: unset
    border: none
    font-weight: 600
    &:hover
      color: var(--bs-pagination-hover-color)
  .page-item.active
    .page-link
      color: $black
