@import ../environment

.new-course-area
  display: flex
  justify-content: center
  padding: 2.25rem
  width: 360px
  height: 239px
  border-radius: 50px
  border: 2px dashed $primary-25

.new-course-area--link
  display: flex
  align-items: center
  font-size: 18px

  .btn
    margin-right: spacer(3)
