@import ../environment

.course-panel
  --bs-link-color: #{$jet}
  --bs-link-hover-color: #{$jet}

  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 2.25rem
  width: 360px
  height: 240px
  border-radius: 50px
  box-shadow: 0px -2px 50px rgba(21, 154, 52, 0.1), 0px -1.10912px 27.9023px rgba(21, 154, 52, 0.0759687), 0px -0.431745px 14.0168px rgba(21, 154, 52, 0.054165), 0px -0.176703px 6.72178px rgba(21, 154, 52, 0.0342921), 0px -0.0670403px 2.57452px rgba(21, 154, 52, 0.0162213)

  &.-new
    --bs-link-color: #{$link-color}
    --bs-link-hover-color: #{$link-hover-color}
    box-shadow: none
    border: 2px dashed $primary-25
    justify-content: center
    align-items: center
    flex-direction: row
    gap: 1rem

.course-panel--title
  display: -webkit-box
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  overflow: hidden
  line-height: $headings-line-height
  font-weight: $headings-font-weight
  font-family: $headings-font-family
  color: $headings-color
  height: $headings-line-height * 4 * 1em
  +font-size($h2-font-size)
