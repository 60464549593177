@import ../environment

.course-explanation
  font-size: 14px
.course-explanation--content
  background-color: $primary-10
  padding: 1rem

.course-explanation--action
  background-color: $primary-10
  display: flex
  flex-direction: row
  align-items: center
  gap: 1rem
  color: $primary
  margin-top: 1px
  padding: 1rem
  font-size: 1.25rem
