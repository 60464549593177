.multiple-choice-asset
  position: relative

  video
    width: 100%

.multiple-choice-asset--button
  position: absolute
  bottom: var(--spacer-3)
  left: var(--spacer-3)
