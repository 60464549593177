@import ../environment

.attachment
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 0.5em

.attachment--icon
  font-size: 2em
  color: $primary
  display: inline-block
  margin-right: 0.25em
  +media-breakpoint-down($mobile-until)
    font-size: 1em

.attachment--title
  font-size: 1em
  line-height: 1em
  color: $sonic-silver
  display: inline-block
  vertical-align: super
  +media-breakpoint-down($mobile-until)
    font-size: 0.75em
