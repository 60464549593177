.tiles
  display: flex
  justify-content: space-between
  flex-flow: row wrap
  align-content: stretch
  align-items: stretch

.tile
  flex-grow: 1
  flex-shrink: 1
  flex-basis: 300px
  margin: 0 12px 24px 0
  flex-flow: row wrap
  align-content: flex-start

.tile--image
  width: 100%
