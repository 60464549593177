@import ../environment

.quiz-slide-answer
   input
      &:checked
        background-color: $jet

      &:focus
        box-shadow: none

   &.-correct
      color: $primary
      input[type='checkbox']
         border-color: $primary
         &:checked
            background-color: $primary
   &.-incorrect
      color: $danger
      input:checked
         background-color: $danger

