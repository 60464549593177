@import ../environment

.text-slide
  margin: 1rem 0
  width: 100%
  +media-breakpoint-down($mobile-until)
    @media (orientation: landscape)

  img, .img-fluid
    max-height: 35vh !important

  .-fullscreen &
    h1
      font-size: 2.8vmax
    h2
      font-size: 2.5vmax
    h3
      font-size: 2.3vmax
    h4
      font-size: 2vmax
    h5
      font-size: 1.8vmax
    h6
      font-size: 1.5vmax
    p
      font-size: 1.3vmax
