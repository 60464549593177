@import ../environment

.pdf-slide-attachment
  display: flex
  flex-direction: row
  gap: 2rem
  align-items: center
  justify-content: stretch
  height: 80%
  +media-breakpoint-down(sm)
    height: 100%
    flex-direction: column

.pdf-slide-attachment--info
  flex-grow: 1

.pdf-slide-attachment--qr-code
  height: 100%
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: contain
