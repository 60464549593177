@import ../environment

.course-form
  box-shadow: $box-shadow
  border-radius: 20px
  margin: 1rem 0
  padding: 16px
  height: 100%

  hr
    margin: 16px -16px

.course-form--title-input
  border: none
  border-bottom: 1px solid $jet-25
  border-radius: unset
  min-height: 1rem !important
  padding:  16px 0 4px 0
  font-size: $h3-font-size
  font-weight: $headings-font-weight
  font-family: $headings-font-family

  &:focus
    border: none
    box-shadow: none
    border-bottom: 1px solid $jet-25

.course-form--actions
  display: flex
  flex-direction: row
  gap: 1rem

.course-form--drop-area
  min-height: 30vh
  overflow-y: auto
  max-height: 40vh
  +beautiful_scrollbar

  .empty-content
    display: none

  &.-empty
    border: 2px dashed #C4E5CC
    border-radius: 10px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    padding: 1.5rem
    .empty-content
      display: block
      text-align: center

.course-form--footer
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  gap: 1rem

.course-form--total-duration
  font-size: .75rem
  color: $sonic-silver
