// Google Fonts does not offer downloading WOFF2 files directly, though they are publicly available.
// We fetched them using http://google-webfonts-helper.herokuapp.com/fonts/montserrat?subsets=latin

@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 800
  src: local(''), url('/var/www/elearning_dvw_p/releases/20250213071253/app/assets/fonts/./montserrat/montserrat-v23-latin-800.woff2') format('woff2')

@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 700
  src: local(''), url('/var/www/elearning_dvw_p/releases/20250213071253/app/assets/fonts/./montserrat/montserrat-v23-latin-700.woff2') format('woff2')
