@import ../environment

.project-component-tile
  --bs-link-color: #{$white}
  --bs-link-hover-color: #{$white}
  border-radius: 20px
  border: 1px solid transparent
  color: $white
  display: grid
  grid-template-columns: 2.25rem auto 2.25rem
  grid-template-rows: 1rem auto 2.25rem
  grid-template-areas: "background-start . ." ". text ." ". . background-end"
  width: var(--tile-width, 100%)

  +media-breakpoint-down($mobile-until)
    grid-template-columns: 1.25rem auto 1.25rem
    grid-template-rows: 1rem auto 1.25rem

.project-component-tile--image
  grid-column: background-start / background-end
  grid-row: background-start / background-end
  border-radius: inherit
  filter: brightness(0.9)
  aspect-ratio: 1.6 / 1

  img
    display: block
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: inherit

.project-component-tile--text
  grid-area: text
  display: flex
  flex-direction: column
  justify-content: end
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.6)
  z-index: 1

.project-component-tile--heading
  font-size: $h2-font-size
  font-family: $headings-font-family
  font-weight: $headings-font-weight
  +media-breakpoint-down($mobile-until)
    font-size: $h3-font-size


