@import ../environment

.search-results--subheading
  margin-top: 6rem
  margin-bottom: 1.75rem
  +media-breakpoint-down($mobile-until)
    margin-top: 4rem
    margin-bottom: 2.25rem

.search-results--course-units
  margin-top: 3rem
  .btn
    margin-top: 1.75rem
    +media-breakpoint-down($mobile-until)
      margin-top: 1.5rem

.search-results--project-components
  margin-top: 1.5rem
  margin-bottom: 6.125rem
  +media-breakpoint-down($mobile-until)
    margin-bottom: 4rem
