@import ../environment

$container-margin: calc(0.5 * (100vw - 100%))

.teacher-intro-section
  display: flex
  justify-content: space-between
  margin-bottom: spacer(5)
  +media-breakpoint-up(xxl)
    margin-bottom: 0
  +media-breakpoint-down($mobile-until)
    flex-direction: column
    text-align: center

.teacher-intro-section--text
  margin-top: spacer(5)
  max-width: 50%
  min-width: 50%

  .-green
    color: $primary

  a
    font-size: $font-size-base

  +media-breakpoint-down($mobile-until)
    max-width: 100%

.teacher-intro-section--image
  img
    width: 100%
  &.-full
    margin-right: calc(-1* $container-margin)

.teacher-intro-section--buttons
  display: flex
  flex-direction: column
  align-items: center
  gap: 1rem
  justify-content: center
  +media-breakpoint-up(sm)
    flex-direction: row
  +media-breakpoint-up($mobile-until)
    gap: 1.5rem
    justify-content: flex-start
