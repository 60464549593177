@import ../environment

.course-unit-tile
  --bs-link-color: #{$white}
  --bs-link-hover-color: #{$white}
  border-radius: 20px
  border: 1px solid transparent
  color: $white
  text-align: center
  display: grid
  grid-template-columns: 1rem auto 1rem
  grid-template-rows: 1rem auto 1rem
  grid-template-areas: "background-start . ." ". text ." ". . background-end"
  min-width: 18rem

.course-unit-tile--image
  grid-column: background-start / background-end
  grid-row: background-start / background-end
  border-radius: inherit
  filter: brightness(0.6)
  aspect-ratio: 1.6 / 1

  img
    display: block
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: inherit

.course-unit-tile--text
  grid-area: text
  display: flex
  flex-direction: column
  justify-content: center
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.6)
  z-index: 1

.course-unit-tile--title
  font-size: 1rem

.course-unit-tile--details
  font-size: 14px
  display: flex
  flex-direction: row
  gap: 0.5rem
  justify-content: center
