\:root
  --bs-body-text-align: left

  @each $scale, $width in $spacers
    --spacer-#{$scale}: #{$width}

  @each $name, $width in $container-max-widths
    --container-max-#{$name}: #{$width}

  --link-decoration: #{$--link-decoration}
  --link-hover-decoration: #{$--link-hover-decoration}
  --header-height: 100px
