@import ../environment
.layout
  min-height: 100vh
  display: flex
  flex-direction: column

  @media print
    min-height: auto
    display: block

.layout--content
  flex-grow: 1
  margin-top: 2rem
  +media-breakpoint-down($mobile-until)
    margin: 0

.layout--header,
.layout--footer
  @media print
    display: none

.layout--header
  +media-breakpoint-down($mobile-until)
    height: var(--header-height)
