.form-check-input
  cursor: pointer

  &:disabled
    cursor: not-allowed


.form-check-label
  cursor: pointer

  input:disabled ~ &
    cursor: not-allowed
