@import ../environment

.slide-badges
  display: flex
  flex-direction: row
  flex-wrap: wrap
  gap: 4px
  padding: 8px
  border-radius: $border-radius
  border: 1px solid $gray
  margin: 1rem 0

.slide-badge
  border: 1px solid transparent
  line-height: inherit
  padding: 4px 8px
  min-width: 80px

  &:hover
    border: 1px solid $primary

  &.-active
    background-color: $primary !important
    color: $white
    --bs-link-color: #{$white}
    --bs-link-hover-color: #{$white}

.slide-badge--icon
  display: inline
  margin-right: 8px
  font-size: 16px

.slide-badge--name
  display: inline
