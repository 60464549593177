@import ../../environment

.btn-primary
  color: white
  &:hover
    color: white
    background-color: $dark-primary
  &:focus, &:active
    border: 2px solid #149832
  &:active
    transform: scale(0.98)
    transition: transform 150ms ease-out

.btn-secondary
  color: $primary
  &:hover
    color: $primary
    background-color: $dark-secondary
  &:focus, &:active
    border: 2px solid #159a3408
  &:active
    transform: scale(0.98)
    transition: transform 150ms ease-out

.btn-link
  font-weight: $btn-font-weight
