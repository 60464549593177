@import ../environment

.navigation-drawer
  position: absolute
  top: var(--header-height)
  up-drawer-viewport
    height: calc(100vh - var(--header-height))
    overflow: hidden
  up-drawer-box
    width: 100vw !important
    min-height: calc(100vh - var(--header-height)) !important
    height: 100%
    background-color: $primary
    --bs-link-color: #{$white}
    --bs-link-hover-color: #{$sonic-silver-opaque}
  up-drawer-content
    height: 100%

  +media-breakpoint-up($mobile-until)
    display: none
