@import ../environment

.player-preview
  height: 75vh
  --carousel-buttons-gap: 7%
  display: flex
  flex-direction: column
  .-fullscreen &
    width: 80vw
    height: 85vh
  +media-breakpoint-down($mobile-until)
    height: 100%

.player-preview--title
  margin-bottom: 3.125rem
  font-size: $h1-font-size
  font-family: $headings-font-family
  font-weight: $headings-font-weight
  +media-breakpoint-down($mobile-until)
    margin-bottom: 1.75rem
    @media (orientation: landscape)
      margin-bottom: 2.25rem


.player-preview--warning
  display: none
  margin-bottom: 1.75rem
  +media-breakpoint-down($mobile-until)
    @media (orientation: portrait)
      display: block


.player-preview--page-info
  grid-area: page-info
  color: $sonic-silver
  margin-top: 1.75rem
  text-align: center
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 2rem


.player-preview--slide-carousel
  display: flex
  flex-grow: 1
  height: 100%


.player-preview--slide
  box-shadow: $box-shadow
  --slide-border-radius: 0.85rem
  border-radius: var(--slide-border-radius)
  width: 100%
  flex-grow: 1

  .-fullscreen &
    .slide
      height: 100%
      border-radius: unset
